import { render, staticRenderFns } from "./PromoterOrder.vue?vue&type=template&id=d168b4d4"
import script from "./PromoterOrder.vue?vue&type=script&lang=js"
export * from "./PromoterOrder.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports